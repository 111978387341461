@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: #e8ffcc;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #3d4137;
  border-radius: 5px;
}

html {
  scroll-behavior: smooth;
}
